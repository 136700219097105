exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-automation-tsx": () => import("./../../../src/pages/blogs/automation.tsx" /* webpackChunkName: "component---src-pages-blogs-automation-tsx" */),
  "component---src-pages-blogs-game-2-d-runner-tsx": () => import("./../../../src/pages/blogs/Game2DRunner.tsx" /* webpackChunkName: "component---src-pages-blogs-game-2-d-runner-tsx" */),
  "component---src-pages-blogs-nonamed-tsx": () => import("./../../../src/pages/blogs/nonamed.tsx" /* webpackChunkName: "component---src-pages-blogs-nonamed-tsx" */),
  "component---src-pages-blogs-pop-tsx": () => import("./../../../src/pages/blogs/pop.tsx" /* webpackChunkName: "component---src-pages-blogs-pop-tsx" */),
  "component---src-pages-blogs-reddit-viewer-tsx": () => import("./../../../src/pages/blogs/redditViewer.tsx" /* webpackChunkName: "component---src-pages-blogs-reddit-viewer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

